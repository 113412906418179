@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");

:root {
  --box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h4 {
  margin: 0;
  text-transform: uppercase;
}

.mainHeading {
  text-align: center;
  margin-top: 2rem;
}

.App {
  margin-top: 50px;
  font-family: "Lato", sans-serif;
  /* min-height: 100vh; */
  --input-width: 95%;
  --input-height: 2rem;
}

.logo {
  height: 150px;
  width: 400px;
}

.listContainer {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  margin: 3rem auto;
  /* width: 800px; */
}

.imagesListContainer {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  margin: 3rem auto;
  /* width: 800px; */
}
