.form {
    width: 500px;
    margin: 10px auto;
    border: 1px solid gray;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    justify-content: space-between;
    border-radius: 12px;
  }
  
  .form label {
    font-weight: bold;
  }
  
  .input {
    width: var(--input-width);
    height: var(--input-height);
    border: 1px solid gray;
    padding-left: 0.5rem;
  }
  
  .submitBtn {
    background-color: #11910d;
    min-width: 150px;
    height: var(--input-height);
    color: white;
    border: none;
    font-size: 1.025rem;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
  }
  
  .submitBtn:hover {
    cursor: pointer;
    background-color: #076404;
  }
  